<template>
  <div id="sidebar-wrapper" class="d-flex flex-column justify-content-between">
    <div class="sidebar-top">
      <router-link :to="{ name: 'Home' }" class="sidebar-heading">
        <img
          src="/assets/icons/logo-black.svg"
          alt="Space Walk"
          class="my-4"
          style="max-width: 100px"
        />
      </router-link>

      <div class="list-group list-group-flush">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.45455 18H2.09091C1.48842 18 1 17.5116 1 16.9091V16.9091V9.42446C1 8.62881 1.31607 7.86575 1.87868 7.30314L7.0605 2.12132C8.23207 0.949749 10.1316 0.949747 11.3031 2.12132L16.485 7.30314C17.0476 7.86575 17.3636 8.62881 17.3636 9.42446V16.9091V16.9091C17.3636 17.5116 16.8752 18 16.2727 18H11.9091M6.45455 18V12.2259C6.45455 11.732 6.65075 11.2583 7 10.9091V10.9091C7.34925 10.5598 7.82293 10.3636 8.31684 10.3636H10.0468C10.5407 10.3636 11.0144 10.5598 11.3636 10.9091V10.9091C11.7129 11.2583 11.9091 11.732 11.9091 12.2259V18M6.45455 18H11.9091"
                stroke="#101010"
                stroke-width="1.6"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <span>Home</span>
        </router-link>

        <router-link
          :to="{ name: 'AdminQuotes' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7988 15.2V13.8362L11.9576 13.8046L11.9581 13.8045C13.4494 13.5062 14.6499 12.8829 15.3208 11.8242L15.3208 11.8243L15.3257 11.8164C15.6576 11.2789 15.8461 10.6653 15.873 10.0341L15.9086 9.2H15.0737H11.998C11.9454 9.2 11.8948 9.17907 11.8574 9.14163C11.8199 9.10417 11.7988 9.05324 11.7988 9V2C11.7988 1.33826 12.3375 0.8 12.9973 0.8H18.9928C19.0455 0.8 19.0961 0.820926 19.1335 0.858368C19.1709 0.895828 19.1921 0.946763 19.1921 1V9V9.07916L19.1973 9.10555C19.1978 9.11699 19.1984 9.13119 19.1988 9.14805C19.2004 9.21055 19.201 9.30647 19.1963 9.42969C19.1869 9.67671 19.1563 10.0281 19.0727 10.437C18.9049 11.258 18.5303 12.2792 17.7141 13.1658C16.5106 14.4714 14.6359 15.2 11.998 15.2H11.7988ZM8.19328 9V9.07916L8.19859 9.1057C8.19909 9.11715 8.19961 9.13139 8.20006 9.14831C8.20173 9.2108 8.20235 9.30671 8.19767 9.42991C8.18828 9.6769 8.15783 10.0283 8.07432 10.4371C7.90662 11.2581 7.53193 12.2793 6.71515 13.166C5.5117 14.4715 3.637 15.2 0.999253 15.2H0.8V13.8362L0.958842 13.8046L0.959297 13.8045C2.45065 13.5062 3.65107 12.8829 4.32201 11.8242L4.32205 11.8243L4.32693 11.8164C4.65884 11.2789 4.84728 10.6653 4.87423 10.0341L4.90985 9.2H4.07496H0.999253C0.946601 9.2 0.895985 9.17907 0.858571 9.14163C0.821138 9.10417 0.8 9.05324 0.8 9V2C0.8 1.33826 1.33872 0.8 1.99851 0.8H7.99403C8.04668 0.8 8.0973 0.820926 8.13471 0.858368C8.17214 0.895829 8.19328 0.946765 8.19328 1V9Z"
                stroke="#101010"
                stroke-width="1.6"
              />
            </svg>
          </div>

          <span>Quotes</span>
        </router-link>

        <router-link
          :to="{ name: 'AdminFigures' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.09208 9.3075H7.90731C6.93215 9.3075 5.99693 8.92012 5.30739 8.23058C4.61785 7.54104 4.23047 6.60582 4.23047 5.63066V5.44589C4.23047 4.47073 4.61785 3.53551 5.30739 2.84597C5.99693 2.15642 6.93216 1.76904 7.90732 1.76904H8.09209C9.06725 1.76904 10.0025 2.15642 10.692 2.84597C11.3815 3.53551 11.7689 4.47073 11.7689 5.44589V5.63066C11.7689 6.60582 11.3815 7.54104 10.692 8.23058C10.0025 8.92012 9.06724 9.3075 8.09208 9.3075Z"
                stroke="#101010"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 18.9998V17.6728C1 16.4538 1.48423 15.2848 2.34615 14.4229V14.4229C3.20808 13.561 4.37711 13.0767 5.59606 13.0767H10.4039C11.6229 13.0767 12.7919 13.561 13.6538 14.4229V14.4229C14.5158 15.2848 15 16.4538 15 17.6728V18.9998"
                stroke="#101010"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <span>Figures</span>
        </router-link>

        <router-link
          :to="{ name: 'AdminConstellations' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="25"
              height="19"
              viewBox="0 0 25 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.9995 3.37428C22.8618 1.54417 19.9683 -1.07733 16.8769 4.11622C13.9091 9.10202 14.0575 8.27105 13.7607 8.71622"
                stroke="#101010"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.999572 3.30775C2.13721 1.47765 5.03076 -1.14386 8.12215 4.04969C11.0899 9.0355 10.9415 8.20453 11.2383 8.6497"
                stroke="#101010"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5705 8.56764H12.2799C11.1378 8.56764 10.0414 9.02244 9.23383 9.83003C8.42764 10.6362 7.97363 11.7307 7.97363 12.8709C7.97363 14.011 8.42764 15.1055 9.23383 15.9117C10.0414 16.7193 11.1378 17.1741 12.2799 17.1741H12.5705C13.7127 17.1741 14.8091 16.7193 15.6167 15.9117C16.4229 15.1055 16.8769 14.011 16.8769 12.8709C16.8769 11.7307 16.4229 10.6362 15.6167 9.83003C14.8091 9.02244 13.7126 8.56764 12.5705 8.56764Z"
                stroke="#101010"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <span>Constellations</span>
        </router-link>

        <router-link
          :to="{ name: 'AdminSkyObjects' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                r="8.3"
                transform="matrix(-1 0 0 1 9 9)"
                stroke="#101010"
                stroke-width="1.4"
              />
              <path
                d="M3.5 3C4.33333 3.66667 5.5 3.5 6.5 5.5C7.61803 7.73607 11 8.5 12.5 7C14.8717 4.62829 16.3333 6.5 16.5 6"
                stroke="#101010"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 7.5C1.16667 8 2.9 9.4 4.5 9C6.5 8.5 7 10.5 7 11.5C7 12.5 6 14.5 3 14.5"
                stroke="#101010"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 16.5C8 16 10 15.5001 10 13C10 12 10.5 10 13 10.5C15.5 11 17 10 17 10"
                stroke="#101010"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <span>Sky Objects</span>
        </router-link>

        <router-link
          :to="{ name: 'AdminSpaceShips' }"
          class="
            list-group-item list-group-item-action
            d-flex
            align-items-center
          "
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 icon"
          >
            <svg
              width="19"
              height="15"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.25 8C17.25 8.30825 17.1126 8.65837 16.7494 9.03994C16.3826 9.42537 15.8164 9.8066 15.0594 10.1431C13.5471 10.8152 11.4055 11.25 9 11.25C6.59454 11.25 4.45286 10.8152 2.94064 10.1431C2.18359 9.8066 1.61737 9.42537 1.25056 9.03994C0.887434 8.65837 0.75 8.30825 0.75 8C0.75 7.69175 0.887434 7.34163 1.25056 6.96006C1.61737 6.57463 2.18359 6.1934 2.94064 5.85693C4.45286 5.18483 6.59454 4.75 9 4.75C11.4055 4.75 13.5471 5.18483 15.0594 5.85693C15.8164 6.1934 16.3826 6.57463 16.7494 6.96006C17.1126 7.34163 17.25 7.69175 17.25 8Z"
                stroke="#101010"
                stroke-width="1.5"
              />
              <path
                d="M5 7C5 7 8.6 9.25 13 7"
                stroke="#101010"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 10.5L1 12.5M9 11.4388V14M15.5 10.5L17.5 12.5"
                stroke="#101010"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 7V5.35187C13 3.75176 12.0464 2.30561 10.5757 1.67529V1.67529C9.56948 1.24406 8.43052 1.24406 7.42432 1.67529V1.67529C5.95359 2.30561 5 3.75176 5 5.35187V7"
                stroke="#101010"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <span>Space Ships</span>
        </router-link>
      </div>
    </div>

    <div
      class="
        sidebar-footer
        d-flex
        flex-column
        align-items-center
        justify-content-center
        text-center
        mb-5
      "
    >
      <div class="avatar">
        <svg
          width="61"
          height="61"
          viewBox="0 0 61 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.75"
            y="0.75"
            width="58.5"
            height="58.5"
            rx="11.25"
            stroke="#101010"
            stroke-opacity="0.3"
            stroke-width="1.5"
          />
          <circle
            cx="55"
            cy="55"
            r="5.25"
            fill="#78F064"
            stroke="white"
            stroke-width="1.5"
          />
        </svg>

        <img v-lazy="user.avatar" :alt="user.name" />
      </div>

      <h5 class="mt-3 w-75">
        {{ user.name }}
      </h5>
    </div>
  </div>
</template>

<script>
// Library
import { mapState } from "vuex";

export default {
  name: "AdminSidebar",
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
  },
};
</script>

<style>
</style>