<template>
  <div class="page-admin">
    <div class="d-flex" id="wrapper" data-aos="fade-right">
      <!-- Admin Sidebar -->
      <AdminSidebar />

      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="container-fluid mt-4 top-bar">
          <!-- Desktop Menu -->
          <div class="row w-100">
            <div class="col">
              <div class="d-flex align-items-center justify-content-end">
                <div @click.prevent="logout" class="icon mr-3">
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 6.00008L14.1175 7.10269C15.1945 8.16536 15.8935 9.55175 16.1075 11.0496V11.0496C16.3483 12.7349 15.9593 14.4498 15.0149 15.8663L14.8841 16.0626C14.0896 17.2543 12.9656 18.1892 11.6492 18.7534V18.7534C9.79187 19.5494 7.6895 19.5494 5.83221 18.7534V18.7534C4.51574 18.1892 3.39178 17.2543 2.5973 16.0626L2.46264 15.8606C1.52075 14.4478 1.13277 12.7373 1.3729 11.0563V11.0563C1.58744 9.55456 2.29081 8.16532 3.37422 7.10346L4.5 6.00008"
                      stroke="#101010"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.74023 1V10.8518"
                      stroke="#101010"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div id="sidebar-toggle" class="icon">
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7H16.9997"
                      stroke="#101010"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 1H9.3808"
                      stroke="#101010"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9998 13H9.38086"
                      stroke="#101010"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Content -->
        <vue-page-transition name="fade-in-right">
          <router-view />
        </vue-page-transition>
      </div>
    </div>
  </div>
</template>

<script>
// Layouts
import AdminSidebar from "@/components/layouts/AdminSidebar.vue";

// Services
import { toastedOption, confirmModal } from "@/services/general.js";

export default {
  name: "Admin",
  components: {
    AdminSidebar,
  },
  created() {
    localStorage.removeItem("theme");
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const menuToggle = document.querySelector("#sidebar-toggle");
      menuToggle.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector("#wrapper").classList.toggle("toggled");
      });
    },
    logout() {
      confirmModal().then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "Home" });
          this.$store.dispatch("auth/logout");
          this.$toasted.success("Logout Success", toastedOption);
        }
      });
    },
  },
};
</script>

<style>
</style>